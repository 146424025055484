<template>
  <v-container v-if="$store.state.storeActive" class="py-2 py-sm-4">
    <v-row no-gutters class="justify-center">
      <v-col cols="12">
        <div class="d-flex align-center justify-center px-0 px-sm-8 pb-0 pb-sm-4 title primary--text">
          <v-icon class="mr-2" color="primary">
            mdi-cash-clock
          </v-icon>
          Order & Booking
        </div>
        <v-divider class="my-4" />
        <!-- <div class="d-flex fill-width justify-center align-center pt-4 pb-10 px-4">
          <v-btn
            :color="view === 'calendar' ? 'primary' : 'secondary lighten-2'"
            depressed
            class="text-capitalize rounded-pill px-6 mr-4"
            style="width: 178px"
            @click.prevent="view = 'calendar'"
          >
            <v-icon small class="mr-2">
              mdi-calendar-clock
            </v-icon>
            Calendar View
          </v-btn>
          <v-btn
            :color="view === 'table' ? 'primary' : 'secondary lighten-2'"
            depressed
            class="text-capitalize rounded-pill px-6"
            style="width: 178px"
            @click.prevent="view = 'table'"
          >
            <v-icon small class="mr-2">
              mdi-view-list-outline
            </v-icon>
            Table View
          </v-btn>
        </div> -->
        <v-row no-gutters class="pb-4">
          <v-col cols="12" class="col-sm-5 pr-0 pr-sm-2 py-2 py-sm-0">
            <v-text-field
              v-model="searchGuestName"
              placeholder="Search Guest Name"
              outlined
              dense
              hide-details
              append-icon="mdi-magnify"
              single-line
              clearable
            />
          </v-col>
          <v-col cols="12" class="col-sm-5 pl-0 pl-sm-2 py-2 py-sm-0">
            <v-menu
              ref="menu"
              v-model="menu"
              :return-value.sync="dates"
              left
              offset-y
              :close-on-content-click="false"
              min-width="auto"
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  :value="dateRangeText"
                  readonly
                  placeholder="Search Name"
                  outlined
                  dense
                  hide-details
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="dates"
                no-title
                light
                class="force-light-font"
                range
              >
                <v-spacer />
                <v-btn
                  text
                  class="text-capitalize"
                  small
                  @click="menu = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  :disabled="dates.length !== 2"
                  text
                  class="text-capitalize"
                  small
                  @click="dates = $dateFromTo(dates), $refs.menu.save(dates), BOOKING_GET()"
                >
                  Ok
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" class="col-sm-2 d-flex justify-end align-center py-2 py-sm-0">
            <download-excel
              :name="(($store.state.storeActive && $store.state.storeData.length) ? $store.state.storeData.filter(r => r.id === $store.state.storeActive)[0].name : 'No Selected Hotel') + ', from ' + (dates[0] + ' to ' + dates[1]) + '.xls'"
              :data="BookingData"
            >
              <v-btn color="secondary" depressed dark class="text-capitalize caption font-weight-bold">
                <v-icon class="mr-2" small>
                  mdi-microsoft-excel
                </v-icon>
                Download
              </v-btn>
            </download-excel>
          </v-col>
        </v-row>
        <Calendar
          v-if="view === 'calendar' && !bookingLoading"
          :title="'Booking Data, ' + (($store.state.storeActive && $store.state.storeData.length) ? $store.state.storeData.filter(r => r.id === $store.state.storeActive)[0].name : 'No Selected Hotel')"
          :room="{}"
          :booking="{}"
          ref-calendar="calendar-booking"
          @reload-booking="BOOKING_GET"
        >
        </Calendar>
        <div v-else-if="!bookingLoading">
          <!-- <div>
          </div> -->
          <div>
            <v-data-table
              :headers="bookingTable.headers"
              :items="BookingData"
              :server-items-length="bookingTable.tableTotal"
              :page.sync="bookingTable.tablePage"
              :items-per-page.sync="bookingTable.tableLimit"
              :options.sync="bookingTable.options"
              :footer-props="{
                itemsPerPageOptions: [25, 50, 100]
              }"
              :loading="bookingLoading"
              :search="searchGuestName"
              dense
              item-key="id"
              loading-text="Loading... Please wait"
            >
              <template #[`item.id`]="{ item }">
                <div class="caption">
                  A{{ ('0000' + item.hotel).slice(-3) }}-T{{ ('000000' + item.id).slice(-5) }}
                </div>
              </template>
              <template #[`item.checkin`]="{ item }">
                <div class="caption d-flex flex-column align-start">
                  <div class="caption d-flex align-center">
                    {{ item.checkin }}
                    <v-icon x-small>
                      mdi-chevron-right
                    </v-icon>
                    {{ item.checkout }}
                  </div>
                </div>
              </template>
              <template #[`item.created`]="{ item }">
                <div class="caption">
                  {{ $localDT(item.created, 'datetime') }}
                </div>
              </template>
              <template #[`item.customer_name`]="{ item }">
                <div class="caption py-2">
                  {{ item.customer_name }}, {{ item.customer_phone }}
                  <div class="caption-small">
                    Guest: {{ item.guest_name }}, {{ item.guest_phone }}, {{ item.guest_email || '-' }}
                    <div>
                      {{ item.guest_adult }} Adult, {{ item.guest_child }} Child
                    </div>
                  </div>
                </div>
              </template>
              <template #[`item.room_name`]="{ item }">
                <div class="caption py-1">
                  <div class="font-weight-bold">
                    {{ parseInt(item.room_quantity) }}x {{ item.room_name }}, {{ item.day_long }} Night{{ parseInt(item.day_long) > 1 ? 's' : '' }}
                  </div>
                  <div class="caption d-flex align-center">
                    {{ item.checkin }}
                    <v-icon x-small>
                      mdi-chevron-right
                    </v-icon>
                    {{ item.checkout }}
                  </div>
                  <!-- <div class="d-flex align-center justify-space-between caption-small" style="min-width: 175px;">
                    <span class="grey--text">
                      None
                    </span>
                    <v-chip v-if="parseInt(item.status) === 1" x-small color="orange darken-1" dark class="c-pointer">
                      Check-In Room
                    </v-chip>
                  </div> -->
                </div>
              </template>
              <template #[`item.status`]="{ item }">
                <div>
                  <div :class="[$BookingStatus(item.status).color + '--text']" class="caption">
                    {{ $BookingStatus(item.status).name }}
                  </div>
                  <div :href="$ParseBookingPayment(item.payments).link || '#'" target="_blank" class="d-flex align-center justify-end caption secondary--text">
                    {{ $price($ParseBookingPayment(item.payments).paid) }}/{{ $price(item.payment_total) }}
                    <!-- <v-icon small class="ml-2" color="secondary">
                      mdi-cash-clock
                    </v-icon> -->
                  </div>
                  <div v-if="parseInt(item.voucher_value) && item.voucher_code" class="d-flex align-center justify-end fill-width green--text caption-small">
                    <v-icon color="green" x-small class="mr-1">
                      mdi-brightness-percent
                    </v-icon>
                    Voucher <strong class="mx-1">{{ item.voucher_code }}</strong>: {{ $price(item.voucher_value || 0) }}
                  </div>
                </div>
              </template>
              <!-- <template #[`item.status`]="{ item }">
                <div :class="[$BookingStatus(item.status).color + '--text']" class="caption">
                  {{ $BookingStatus(item.status).name }}
                </div>
              </template> -->
              <template #[`item.options`]="{ item }">
                <div
                  v-if="$role(['manager'], $store.state.user.role)"
                  class="py-2"
                  style="min-width: 100px;"
                >
                  <v-btn
                    v-if="!parseInt(item.status)"
                    :loading="dialogPayAtHotel.loading"
                    :outlined="$ParseBookingPayment(item.payments).link ? true : false"
                    :depressed="$ParseBookingPayment(item.payments).link ? false : true"
                    :color="$ParseBookingPayment(item.payments).link ? 'green' : 'blue'"
                    :href="$ParseBookingPayment(item.payments).link || null"
                    target="_blank"
                    small
                    dark
                    class="text-capitalize"
                    @click="$ParseBookingPayment(item.payments).link ? true : (PayAtHotelOpen(item, $ParseBookingPayment(item.payments)))"
                  >
                    <v-icon small class="mr-1">
                      mdi-cash-clock
                    </v-icon>
                    {{ $ParseBookingPayment(item.payments).link ? 'Payment Link' : 'Pay At Hotel' }}
                  </v-btn>
                  <v-btn v-else-if="parseInt(item.status) === 1" depressed small dark color="secondary" class="text-capitalize" @click.prevent="OpenCheckIn(item)">
                    <v-icon small class="mr-1">
                      mdi-door-open
                    </v-icon>
                    Check-In Room
                  </v-btn>
                </div>
                <div v-else>
                  -
                </div>
              </template>
              <!-- <template v-slot:footer>
                <div :class="[$vuetify.theme.dark ? '' : 'grey lighten-4']" class="pt-2 pb-3 px-4 px-md-8">
                  <v-divider class="mt-2 mb-3" />
                  <div class="d-flex justify-space-between flex-column flex-sm-row">
                    <div class="d-flex align-center justify-center justify-sm-start font-weight-bold">
                      Summary Reports :
                    </div>
                    <div class="d-flex justify-center align-center align-sm-end flex-column body-2">
                      <span class="font-weight-bold caption-small">
                        Subtotal : {{ parseInt(tableSummary.subtotal) | price }}
                      </span>
                      <v-divider class="my-1" style="width: 75px;" />
                      <span class="font-weight-bold caption-small">
                        Shipping : {{ parseInt(tableSummary.shipping) | price }}
                      </span>
                      <v-divider class="my-1" style="width: 75px;" />
                      <span class="font-weight-bold caption-small">
                        Total {{ parseInt(tableSummary.total) | price }}
                      </span>
                    </div>
                  </div>
                </div>
              </template> -->
            </v-data-table>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialogRoomList.show"
      scrollable
      persistent
      max-width="375"
    >
      <v-card>
        <v-card-title class="body-1 justify-center font-weight-bold">
          Check-In
          <!-- {{ dialogRoomList.booking ? dialogRoomList.booking.id : '' }} -->
        </v-card-title>
        <v-divider />
        <v-card-text v-if="dialogRoomList.booking" class="px-4 py-3" style="max-height: 75vh;">
          <div v-for="(bs, iBs) in dialogRoomList.booking.schedules" :key="'booking-schedule-' + iBs" class="d-flex fill-width align-center justify-start justify-sm-space-between py-1">
            <div class="d-flex align-center fill-width subtitle-1">
              <v-icon class="mr-2" color="secondary">
                mdi-calendar-clock-outline
              </v-icon>
              {{ $localDT(bs.booking_date, 'displaylocal') }}
            </div>
            <v-select
              v-model.number="dialogRoomList.booking.schedules[iBs].room_selected"
              :disabled="dialogRoomList.loading"
              :items="dialogRoomListData"
              label="Check-In Room"
              item-text="name"
              item-value="id"
              outlined
              dense
              hide-details
              class="fill-width"
              @change="UpdateSchedule(iBs, {
                id: bs.id,
                hotel: dialogRoomList.booking.hotel,
                room: dialogRoomList.booking.room,
                booking_date: bs.booking_date,
                room_selected: dialogRoomList.booking.schedules[iBs].room_selected
              })"
            />
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="red darken-1"
            text
            class="mr-2 text-capitalize font-weight-bold"
            @click="BOOKING_GET(), dialogRoomList.show = false"
          >
            Close
          </v-btn>
          <!-- <v-spacer /> -->
          <!-- <v-btn
            color="secondary"
            depressed
            class="mr-2 text-capitalize font-weight-bold"
            @click="UpdateSchedules()"
          >
            Confirm
          </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogPayAtHotel.show"
      scrollable
      persistent
      max-width="300"
    >
      <v-card>
        <v-card-title class="body-1 justify-center font-weight-bold">
          Confirm - Pay At Hotel
        </v-card-title>
        <v-divider />
        <v-card-text v-if="dialogPayAtHotel.data" class="px-5 py-4" style="max-height: 75vh;">
          <v-text-field
            v-model.number="dialogPayAtHotel.data.amount_paid"
            disabled
            outlined
            dense
            hide-details
            prefix="Rp"
            placeholder="Amount"
            @keypress="$NumberOnly"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            color="red darken-1"
            text
            class="mr-2 text-capitalize font-weight-bold"
            @click="dialogPayAtHotel.show = false"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            color="secondary"
            depressed
            class="px-4 text-capitalize font-weight-bold"
            @click="PayAtHotelProcess()"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Booking',
  data: vm => ({
    searchGuestName: '',
    view: 'table',
    calendarNav: 0,
    bookingLoading: false,
    booking: {
      rooms_list_total: 0,
      unpaid: [], // STATUS : 0 unpaid
      confirmed: [], // STATUS : 1 confirmed & room settled, 2 checked_in/ongoing & room settled, 3 checked_out/complete
      canceled: [] // STATUS : 98 canceled refund, 99 canceled no reason, 100 deleted
    },
    bookingTable: {
      headers: [
        {
          text: 'Booking ID',
          value: 'id'
        },
        { text: 'Created', value: 'created' },
        // { text: 'Check In/Out', value: 'checkin', sortable: false },
        { text: 'Customer', value: 'customer_name', sortable: false },
        { text: 'Booking', value: 'room_name', sortable: false },
        { text: 'Payment', value: 'status', align: 'right' },
        // { text: 'Status', value: 'status', align: 'start' },
        { text: 'Options', value: 'options', align: 'center', sortable: false }
      ],
      data: [],
      dates: [],
      menu: false,
      tableSearch: '',
      tableAchievement: 3,
      tableTotal: 0,
      tablePage: 1,
      tableLimit: 25,
      tableSearchStatus: null,
      tableSearchPayment: '',
      options: {}
    },
    dialogBooking: {
      show: false,
      data: null
    },
    dialogRoomList: {
      show: false,
      loading: false,
      booking: null,
      data: [],
      selected: 0
    },
    dialogPayAtHotel: {
      show: false,
      loading: false,
      data: null
    },
    menu: false,
    dates: vm.$dateFromTo([vm.$localDT(new Date(), 'datedefault'), vm.$localDT(new Date(), 'datedefault')])
  }),
  computed: {
    BookingData () {
      if (this.searchGuestName) {
        const rgx = new RegExp(this.searchGuestName, 'gi')
        return this.bookingTable.data.filter(r => ((r.customer_name + ' ' + r.guest_name).trim() ? (r.customer_name + ' ' + r.guest_name).match(rgx) : false))
      }
      return this.bookingTable.data
    },
    dateRangeText () {
      return this.dates.map(r => this.formatDate(r)).join(' - ')
    },
    dialogRoomListData () {
      const roomAvailable = this.dialogRoomList.data
      if (this.dialogRoomList.show && this.dialogRoomList.booking) {
        const schedules = this.dialogRoomList.booking.schedules
        for (let s = 0; s < schedules.length; s++) {
          const sch = Object.assign({}, schedules[s])
          if (parseInt(sch.room_selected) && sch.room_selected_name) {
            const exist = roomAvailable.find(r => parseInt(r.id) === parseInt(sch.room_selected))
            if (!exist) {
              roomAvailable.unshift({
                id: parseInt(sch.room_selected),
                name: sch.room_selected_name
              })
            }
          }
        }
      }
      return roomAvailable
    },
    ...mapState({
      storeActive: state => parseInt(state.storeActive || 0)
    })
    // dateRangeText () {
    //   return this.bookingTable.dates.join(' ~ ')
    // }
  },
  watch: {
    view () {
      this.BOOKING_GET(1)
    },
    'bookingTable.dates': {
      handler (v) {
        if (v.length === 2) {
          this.BOOKING_GET(2)
        }
      },
      deep: true
    },
    // 'bookingTable.options' (v) {
    //   console.log(v)
    //   this.BOOKING_GET(3)
    //   console.log('------')
    // },
    storeActive (v) {
      if (v) {
        this.BOOKING_GET(4)
      }
    }
  },
  mounted () {
    this.BOOKING_GET(5)
  },
  methods: {
    formatDate (date) {
      if (!date) { return null }
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    PayAtHotelOpen (v, payments) {
      if (this.dialogPayAtHotel.loading) {
        this.$store.dispatch('TOAST', { show: true, message: 'Processing, please wait!' })
        return false
      }
      if (!parseInt(v.payment_total)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Invalid amount!' })
        return false
      }
      const toPaid = (parseInt(v.payment_total) - parseInt(payments.paid)) || 0
      const payAtHotel = payments.data.find(r => parseInt(r.payment_type))
      if (!payAtHotel) {
        this.$store.dispatch('TOAST', { show: true, message: 'Ops, invalid methods!' })
        return false
      }
      if (toPaid > 0) {
        this.dialogPayAtHotel.data = Object.assign({}, {
          id: payAtHotel.id,
          booking: parseInt(v.id),
          amount: parseInt(v.payment_total),
          amount_paid: toPaid,
          payment_inout: 1,
          payment_type: 1,
          status: 1
        })
        this.dialogPayAtHotel.show = true
      } else {
        this.$store.dispatch('TOAST', { show: true, message: 'Something went wrong, please contact administrator!' })
      }
    },
    PayAtHotelProcess () {
      if (!confirm('Are you sure you want to confirm the payment?')) {
        return false
      }
      this.dialogPayAtHotel.loading = true
      this.$store.dispatch('booking/PAYMENT_PROCESS', {
        hotel: this.storeActive,
        params: this.dialogPayAtHotel.data
      })
        .then((res) => {
          let success = false
          this.dialogPayAtHotel.loading = false
          if (res.status) {
            success = res.data.data.status || false
          }
          if (success) {
            this.$store.dispatch('TOAST', { show: true, message: 'Confirmed!' })
            this.dialogPayAtHotel.show = false
            this.BOOKING_GET()
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Failed, please contact administrator!' })
          }
        })
    },
    async UpdateSchedule (index, data) {
      await this.$store.dispatch('booking/BOOKING_SCHEDULE_PROCESS', Object.assign({}, data)).then((res) => {
        if (res.status) {
          if (parseInt(res.data.data.id)) {
            this.$store.dispatch('TOAST', { show: true, message: 'Room selected!' })
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Ops, the room is no longer available!' })
          }
        } else {
          this.$store.dispatch('TOAST', { show: true, message: 'Ops, something went wrong!' })
        }
      })
    },
    OpenCheckIn (booking) {
      this.dialogRoomList.selected = 0
      this.dialogRoomList.booking = Object.assign({}, booking)
      this.dialogRoomList.data = []
      this.dialogRoomList.show = true
      this.dialogRoomList.loading = true
      this.$store.dispatch('room/ROOMS_LIST_GET', {
        room: booking.room,
        q: '?date=' + booking.checkin
      }).then((res) => {
        this.dialogRoomList.loading = false
        if (res.status) {
          const d = res.data.data
          this.dialogRoomList.data = d
          this.dialogRoomList.show = true
        } else {
          this.$store.dispatch('TOAST', { show: true, message: 'Something went wrong!' })
        }
      })
    },
    BOOKING_GET () {
      if (this.storeActive && this.$store.state.storeData.length) {
        const findHotel = this.$store.state.storeData.find(r => parseInt(r.id) === this.storeActive)
        this.booking.rooms_list_total = parseInt(findHotel.rooms_list_total) || 0
        this.booking.unpaid = []
        this.booking.confirmed = []
        this.booking.canceled = []
        this.bookingLoading = true

        this.bookingTable.data = []
        this.bookingTable.tableTotal = 0
        const queryFromTo = '&from=' + (this.dates[0] || '') + '&to=' + (this.dates[1] || '')
        this.$store.dispatch('booking/BOOKING_GET', '?page=1&limit=10000&hotel=' + this.storeActive + ('&checkin=' + (this.view === 'calendar' ? 1 : 0)) + queryFromTo)
          .then((res) => {
            this.bookingLoading = false
            if (res.status) {
              if (this.view === 'calendar') {
                const booking = res.data.data || []
                for (let b = 0; b < booking.length; b++) {
                  const bb = booking[b]
                  if (!parseInt(bb.status)) {
                    this.booking.unpaid.push(Object.assign({}, bb))
                  } else if (parseInt(bb.status) >= 1 && parseInt(bb.status) <= 3) {
                    this.booking.confirmed.push(Object.assign({}, bb))
                  } else {
                    this.booking.canceled.push(Object.assign({}, bb))
                  }
                }
              } else {
                this.bookingTable.tableTotal = res.data.data.length ? (parseInt(res.data.data[0].rows) || 0) : 0
                this.bookingTable.data = (res.data.data || []).map((r) => {
                  if (r.schedules) {
                    r.schedules = r.schedules.map((rs) => {
                      const rsPlitted = rs ? rs.split('|') : []
                      if (rsPlitted.length === 4) {
                        return {
                          id: parseInt(rsPlitted[0]),
                          booking_date: rsPlitted[1],
                          room_selected: parseInt(rsPlitted[2]) || 0,
                          room_selected_name: rsPlitted[3] || '-'
                        }
                      }
                      return ''
                    }).filter(rss => rss)
                  }
                  return r
                })
              }
            } else {
              // FAILED TO LOAD
            }
          })
      } else {
        this.$store.dispatch('TOAST', { show: true, message: 'No hotel data or no hotel selected!' })
      }
    },
    GetDatesQueries () {
      const fromTo = this.bookingTable.dates
      if (fromTo) {
        if (fromTo.length === 2) {
          if ((new Date(fromTo[0])).getTime() < (new Date(fromTo[1])).getTime()) {
            return '&from=' + fromTo[0] + '&to=' + fromTo[1]
          } else {
            return '&from=' + fromTo[1] + '&to=' + fromTo[0]
          }
        }
      }
      return ''
    }
  }
}
</script>

<style lang="scss">
</style>
