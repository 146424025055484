var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"py-0 py-sm-8 px-0 px-sm-4"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"col-sm-4",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"d-flex align-center justify-center pa-4 fill-width fill-height secondary",attrs:{"dark":"","outlined":""},on:{"click":function($event){$event.preventDefault();return _vm.ACCOUNT_SHOW()}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Customer ")],1)],1),_c('v-col',{staticClass:"col-sm-8 white",attrs:{"cols":"12"}},[_c('div',{staticClass:"px-4 pt-4 pb-0 pb-sm-4"},[_c('v-text-field',{staticClass:"rounded-0",attrs:{"outlined":"","dense":"","hide-details":"","placeholder":"Search (Account Name, Email, Phone)","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.tableSearch),callback:function ($$v) {_vm.tableSearch=$$v},expression:"tableSearch"}})],1)]),_c('v-col',{staticClass:"pt-4",attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"items":_vm.table,"item-key":"id","server-items-length":_vm.tableTotal,"page":_vm.tablePage,"items-per-page":_vm.tableLimit,"options":_vm.options,"footer-props":{
          itemsPerPageOptions: [25, 50, 100]
        },"loading":_vm.tableLoading,"loading-text":"Loading... Please wait"},on:{"update:page":function($event){_vm.tablePage=$event},"update:itemsPerPage":function($event){_vm.tableLimit=$event},"update:items-per-page":function($event){_vm.tableLimit=$event},"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center text-uppercase"},[_c('v-icon',{staticClass:"mr-1"},[_vm._v(" mdi-barcode ")]),_c('span',{staticClass:"font-weight-bold caption"},[_vm._v(" AMTH/CUSTOMER/"+_vm._s(item.id)+" ")])],1)]}},{key:"item.name",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column py-2"},[_c('div',{staticClass:"d-flex align-center"},[_c('div',[_vm._v(" "+_vm._s(item.name || 'Subscriber')+" ")])])])]}},{key:"item.email",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"py-2"},[_vm._v(" "+_vm._s(item.email)+" "),_c('div',[_vm._v(" "+_vm._s(item.phone)+" ")])])]}},{key:"item.subscribed",fn:function(ref){
        var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(parseInt(item.subscribed) ? 'Yes' : 'No')+" ")])]}},{key:"item.booking_total",fn:function(ref){
        var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.booking_total)+"x ")])]}},{key:"item.status",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('v-chip',{attrs:{"color":parseInt(item.status) === 100 ? 'red' : parseInt(item.status) === 99 ? 'yellow darken-2' : parseInt(item.status) === 1 ? 'green' : 'grey',"outlined":"","dark":"","small":""}},[_vm._v(" "+_vm._s(parseInt(item.status) === 100 ? 'Deleted' : parseInt(item.status) === 99 ? 'Blocked' : parseInt(item.status) === 1 ? 'Active' : 'Not Active')+" ")])],1)]}},{key:"item.option",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticStyle:{"min-width":"100px"}},[(_vm.$role(['manager'], _vm.$store.state.user.role))?_c('v-btn',{staticClass:"rounded-0 text-capitalize",attrs:{"depressed":"","small":"","dark":"","color":"orange"},on:{"click":function($event){$event.preventDefault();return _vm.ACCOUNT_SHOW(item)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-information-outline ")]),_vm._v(" Detail ")],1):_vm._e()],1)]}}],null,true)})],1)],1),_c('v-dialog',{attrs:{"persistent":"","scrollable":"","max-width":"340"},model:{value:(_vm.dialogAccount.show),callback:function ($$v) {_vm.$set(_vm.dialogAccount, "show", $$v)},expression:"dialogAccount.show"}},[(_vm.dialogAccount.data)?_c('v-card',[_c('v-card-title',{staticClass:"body-1 justify-center"},[_vm._v(" "+_vm._s(_vm.dialogAccount.data.id ? 'Update Account' : 'Create New Account')+" ")]),_c('v-divider'),_c('v-card-text',{staticClass:"pt-4",staticStyle:{"max-height":"300px"}},[_c('v-text-field',{staticClass:"mb-4 rounded-0",attrs:{"outlined":"","dense":"","hide-details":"","label":"Nama"},model:{value:(_vm.dialogAccount.data.name),callback:function ($$v) {_vm.$set(_vm.dialogAccount.data, "name", $$v)},expression:"dialogAccount.data.name"}}),_c('v-text-field',{staticClass:"mb-4 rounded-0",attrs:{"outlined":"","dense":"","hide-details":"","label":"Email"},model:{value:(_vm.dialogAccount.data.email),callback:function ($$v) {_vm.$set(_vm.dialogAccount.data, "email", $$v)},expression:"dialogAccount.data.email"}}),_c('v-text-field',{staticClass:"mb-4 rounded-0",attrs:{"outlined":"","dense":"","hide-details":"","label":"No.HP / Whatsapp"},on:{"keypress":_vm.$NumOnly},model:{value:(_vm.dialogAccount.data.phone),callback:function ($$v) {_vm.$set(_vm.dialogAccount.data, "phone", $$v)},expression:"dialogAccount.data.phone"}}),_c('v-select',{staticClass:"rounded-0",attrs:{"items":[
            { name: 'Active', value: 1 },
            { name: 'Not Active', value: 0 }
          ],"item-text":"name","item-value":"value","outlined":"","dense":"","hide-details":"","label":"Account Status"},model:{value:(_vm.dialogAccount.data.status),callback:function ($$v) {_vm.$set(_vm.dialogAccount.data, "status", _vm._n($$v))},expression:"dialogAccount.data.status"}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){_vm.dialogAccount.show = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){return _vm.ACCOUNT_PROCESS()}}},[_vm._v(" Save ")])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }