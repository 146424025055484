<template>
  <v-container class="py-0 py-sm-8 px-0 px-sm-4">
    <v-row no-gutters>
      <v-col cols="12" class="col-sm-4">
        <v-card :class="[$role([], $store.state.user.role) ? 'secondary' : 'grey']" class="d-flex align-center justify-center pa-4 fill-width fill-height" dark outlined @click.prevent="$role([], $store.state.user.role) ? STORE_SHOW() : null">
          <v-icon small class="mr-2">
            mdi-plus
          </v-icon>
          Create Client
        </v-card>
      </v-col>
      <v-col cols="12" class="col-sm-6 white">
        <div class="px-4 pt-4 pb-0 pb-sm-4">
          <v-text-field
            v-model="tableSearch"
            outlined
            dense
            hide-details
            class="rounded-0"
            placeholder="Search Store Name ..."
            prepend-inner-icon="mdi-magnify"
          />
        </div>
      </v-col>
      <v-col cols="12" class="col-sm-2 white">
        <!-- <div class="pa-4 pl-sm-0">
          <v-select
            v-model="tableSearchStatus"
            :items="[{ name: 'All Status', value: null }, { name: 'Pending', value: 0 }, { name: 'Packaging', value: 1 }, { name: 'Ready To Ship', value: 2 }, { name: 'Shipped', value: 3 }, { name: 'Complete', value: 4 }]"
            item-text="name"
            item-value="value"
            outlined
            dense
            hide-details
            class="rounded-0"
            label="Order Status"
          />
        </div> -->
      </v-col>
      <v-col cols="12" class="pt-4">
        <v-data-table
          dense
          :headers="headers"
          :items="$store.state.user.role === 'manager' ? table.filter(r => parseInt(r.id) === parseInt($store.state.user.store)) : table"
          item-key="id"
          :server-items-length="tableTotal"
          :page.sync="tablePage"
          :items-per-page.sync="tableLimit"
          :options.sync="options"
          :footer-props="{
            itemsPerPageOptions: [8, 15, 50, 100]
          }"
          :loading="tableLoading"
          loading-text="Loading... Please wait"
        >
          <template v-slot:[`item.id`]="{ item }">
            <div>
              #AMITHYA/{{ item.id }}
            </div>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <div class="py-2">
              <div>
                {{ item.name }}
              </div>
            </div>
          </template>
          <template v-slot:[`item.phone`]="{ item }">
            <div>
              {{ item.phone }}
            </div>
          </template>
          <template v-slot:[`item.kecamatan_name`]="{ item }">
            <div>
              <span v-if="parseInt(item.area)">
                {{ $area(item.area).name }}
              </span>
              <span v-else class="red--text caption-small">
                belum di set
              </span>
            </div>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <div class="text-center">
              <v-chip :color="parseInt(item.status) === 100 ? 'red' : parseInt(item.status) === 99 ? 'yellow darken-2' : parseInt(item.status) === 1 ? 'green' : 'grey'" outlined dark small>
                {{ parseInt(item.status) === 100 ? 'Deleted' : parseInt(item.status) === 99 ? 'Blocked' : parseInt(item.status) === 1 ? 'Active' : 'Not Active' }}
              </v-chip>
            </div>
          </template>
          <template v-slot:[`item.option`]="{ item }">
            <div style="min-width: 100px;">
              <v-btn v-if="$role(['manager'], $store.state.user.role)" depressed small dark color="orange" class="rounded-0 text-capitalize" @click.prevent="STORE_SHOW(item)">
                <v-icon small class="mr-2">
                  mdi-information-outline
                </v-icon>
                Detail
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialogStore.show"
      persistent
      scrollable
      max-width="720"
    >
      <v-card v-if="dialogStore.data" >
        <v-card-title class="body-1 justify-center">
          {{ dialogStore.data.id ? 'Update Client' : 'Create New Client' }}
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-6" style="max-height: 90vh;">
          <v-row>
            <v-col cols="12" class="col-sm-6 pr-3">
              <v-text-field
                v-model="dialogStore.data.name"
                outlined
                dense
                hide-details
                label="Client Name"
                class="mb-4 rounded-0"
              />
              <v-select
                v-model.number="dialogStore.data.area"
                :items="$store.state.areas"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class="mb-4 rounded-0"
                label="Client Area"
              />
              <v-text-field
                v-model="dialogStore.data.phone"
                outlined
                dense
                hide-details
                label="No.HP / Whatsapp"
                class="mb-4 rounded-0"
                @keypress="$NumOnly"
              />
              <v-textarea
                v-model="dialogStore.data.description"
                outlined
                rows="4"
                dense
                hide-details
                label="About / Description"
                class="mb-4 rounded-0 body-2"
              />
              <div class="d-flex fill-width align-center justify-center">
                <v-text-field
                  v-model="dialogStore.data.lat"
                  outlined
                  dense
                  hide-details
                  label="Pos.Latitude"
                  class="mb-4 rounded-0"
                />
                <v-text-field
                  v-model="dialogStore.data.lng"
                  outlined
                  dense
                  hide-details
                  label="Pos.Longitude"
                  class="mb-4 rounded-0"
                />
              </div>
              <v-select
                v-model.number="dialogStore.data.featured"
                :items="[
                  { name: 'Yes', value: 1 },
                  { name: 'No', value: 0 }
                ]"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class="mb-4 rounded-0"
                label="Show as Featured (Home Page)"
              />
              <v-select
                v-model.number="dialogStore.data.pay_at_hotel"
                :items="[
                  { name: 'Yes', value: 1 },
                  { name: 'No', value: 0 }
                ]"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class="mb-4 rounded-0"
                label="Activate 'Pay at Hotel' Feature"
              />
              <v-select
                v-model.number="dialogStore.data.status"
                :items="[
                  { name: 'Active', value: 1 },
                  { name: 'Not Active', value: 0 }
                ]"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class="mb-4 rounded-0"
                label="Live / Published"
              />
            </v-col>
            <v-col cols="12" class="col-sm-6 pl-3">
              <div class="caption">
                Logo
              </div>
              <div class="py-2 mb-2 d-flex fill-width" style="position: relative;">
                <v-img
                  :src="dialogStore.data.logo"
                  :aspect-ratio="2/1"
                  class="purple lighten-5 rounded p-relative"
                  style="max-height:80px;"
                >
                  <div class="d-flex fill-width fill-height align-center justify-center caption text-center c-pointer" style="position: absolute;top: 0px;left:0px;" @click.prevent="UploadImage()">
                    <span v-if="loadingImageUpload">
                      uploading ...
                    </span>
                    <span v-else>
                      {{ dialogStore.data.logo ? 'Change Client Logo' : 'Upload Client Logo' }}
                    </span>
                  </div>
                </v-img>
              </div>
              <div class="caption">
                Banner
              </div>
              <div class="py-2 mb-2 d-flex fill-width justify-center" style="position: relative;">
                <v-img
                  :src="dialogStore.data.banner"
                  :aspect-ratio="2/1"
                  class="purple lighten-5 rounded p-relative fill-width"
                  style="max-height:120px;"
                >
                  <div class="d-flex fill-width fill-height align-center justify-center caption text-center c-pointer" style="position: absolute;top: 0px;left:0px;" @click.prevent="UploadImage('banner')">
                    <span v-if="loadingImageUpload">
                      uploading ...
                    </span>
                    <span v-else>
                      {{ dialogStore.data.banner ? 'Change Client Banner' : 'Upload Client Banner' }}
                    </span>
                  </div>
                </v-img>
              </div>
              <div class="caption">
                Galleries
              </div>
              <div class="py-2 mb-4 d-flex fill-width justify-center" style="position: relative;">
                <v-row>
                  <v-col cols="6" class="col-sm-4 p-relative">
                    <div class="fill-width grey lighten-5 rounded-lg" style="padding-bottom: 100%;" />
                    <div class="fill-width fill-height d-flex align-center justify-center c-pointer" style="position: absolute;top:0px;left:0px;" @click.prevent="ProcessGallery()">
                      <v-icon color="secondary lighten-2" class="">
                        mdi-plus
                      </v-icon>
                    </div>
                  </v-col>
                  <v-col v-for="(g, iG) in dialogStore.data.galleries" :key="'store-gallery-' + iG" cols="6" class="col-sm-4 p-relative">
                    <v-img
                      :aspect-ratio="1/1"
                      :lazy-src="g.src || '/no-image.jpg'"
                      :src="g.src || '/no-image.jpg'"
                      class="purple lighten-5 rounded fill-width"
                    />
                    <div class="fill-width fill-height d-flex align-center justify-center" style="position: absolute;top:0px;left:0px;">
                      <v-icon small color="secondary lighten-2" class="mr-1" @click.prevent="ProcessGallery(g, iG)">
                        mdi-reload
                      </v-icon>
                      <v-icon small color="red lighten-2" class="ml-1" @click.prevent="ProcessGallery('delete', iG)">
                        mdi-delete
                      </v-icon>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" class="pb-8 pt-4">
              <v-textarea
                v-model="dialogStore.data.tripadvisor"
                outlined
                rows="4"
                dense
                hide-details
                label="Tripadvisor Script"
                class="mb-4 rounded-0 body-2"
              />
            </v-col>
            <v-col cols="12" class="pb-8 pt-4">
              <v-divider />
            </v-col>
            <v-col cols="12" class="col-sm-4">
              <v-select
                v-model.number="dialogStore.data.pub_fac_restaurant"
                :items="[ { name: 'Yes', value: 1 }, { name: 'No', value: 0 } ]"
                label="Restaurant"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class="mb-4"
              />
            </v-col>
            <v-col cols="12" class="col-sm-4 px-0 px-sm-4">
              <v-select
                v-model.number="dialogStore.data.pub_fac_cafe"
                :items="[ { name: 'Yes', value: 1 }, { name: 'No', value: 0 } ]"
                label="Pub / Cafe"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class="mb-4"
              />
            </v-col>
            <v-col cols="12" class="col-sm-4">
              <v-select
                v-model.number="dialogStore.data.pub_fac_live_music"
                :items="[ { name: 'Yes', value: 1 }, { name: 'No', value: 0 } ]"
                label="Live Music"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class="mb-4"
              />
            </v-col>
            <v-col cols="12" class="col-sm-4">
              <v-select
                v-model.number="dialogStore.data.pub_fac_shopping"
                :items="[ { name: 'Yes', value: 1 }, { name: 'No', value: 0 } ]"
                label="Shopping"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class="mb-4"
              />
            </v-col>
            <v-col cols="12" class="col-sm-4 px-0 px-sm-4">
              <v-select
                v-model.number="dialogStore.data.pub_fac_atm"
                :items="[ { name: 'Yes', value: 1 }, { name: 'No', value: 0 } ]"
                label="Bank / ATM"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class="mb-4"
              />
            </v-col>
            <v-col cols="12" class="col-sm-4">
              <v-select
                v-model.number="dialogStore.data.pub_fac_beach"
                :items="[ { name: 'Yes', value: 1 }, { name: 'No', value: 0 } ]"
                label="Beach"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class="mb-4"
              />
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" class="col-sm-4">
              <v-select
                v-model.number="dialogStore.data.pub_fac_mountain"
                :items="[ { name: 'Yes', value: 1 }, { name: 'No', value: 0 } ]"
                label="Mountain"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class="mb-4"
              />
            </v-col>
            <v-col cols="12" class="col-sm-4 px-0 px-sm-4">
              <v-select
                v-model.number="dialogStore.data.pub_fac_garden"
                :items="[ { name: 'Yes', value: 1 }, { name: 'No', value: 0 } ]"
                label="Garden"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class="mb-4"
              />
            </v-col>
            <v-col cols="12" class="col-sm-4">
              <v-select
                v-model.number="dialogStore.data.pub_fac_swimming_pool"
                :items="[ { name: 'Yes', value: 1 }, { name: 'No', value: 0 } ]"
                label="Swimming Pool"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class="mb-4"
              />
            </v-col>
            <v-col cols="12" class="col-sm-4">
              <v-select
                v-model.number="dialogStore.data.pub_fac_gym"
                :items="[ { name: 'Yes', value: 1 }, { name: 'No', value: 0 } ]"
                label="Gym"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class="mb-4"
              />
            </v-col>
            <v-col cols="12" class="col-sm-4 px-0 px-sm-4">
              <v-select
                v-model.number="dialogStore.data.pub_fac_karaoke"
                :items="[ { name: 'Yes', value: 1 }, { name: 'No', value: 0 } ]"
                label="Karaoke"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class="mb-4"
              />
            </v-col>
            <v-col cols="12" class="col-sm-4">
              <v-select
                v-model.number="dialogStore.data.pub_fac_golf"
                :items="[ { name: 'Yes', value: 1 }, { name: 'No', value: 0 } ]"
                label="Golf"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class="mb-4"
              />
            </v-col>
            <v-col cols="12" class="col-sm-4">
              <v-select
                v-model.number="dialogStore.data.pub_fac_billiard"
                :items="[ { name: 'Yes', value: 1 }, { name: 'No', value: 0 } ]"
                label="Billiard"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class="mb-4"
              />
            </v-col>
            <v-col cols="12" class="col-sm-4 px-0 px-sm-4">
              <v-select
                v-model.number="dialogStore.data.pub_fac_bowling"
                :items="[ { name: 'Yes', value: 1 }, { name: 'No', value: 0 } ]"
                label="Bowling"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class="mb-4"
              />
            </v-col>
            <v-col cols="12" class="col-sm-4">
              <v-select
                v-model.number="dialogStore.data.pub_fac_football"
                :items="[ { name: 'Yes', value: 1 }, { name: 'No', value: 0 } ]"
                label="Footbal"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class="mb-4"
              />
            </v-col>

            <v-col cols="12" class="col-sm-4">
              <v-select
                v-model.number="dialogStore.data.pub_fac_carpark"
                :items="[ { name: 'Yes', value: 1 }, { name: 'No', value: 0 } ]"
                label="Carpark"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class="mb-4"
              />
            </v-col>
            <v-col cols="12" class="col-sm-4">
              <v-select
                v-model.number="dialogStore.data.pub_fac_wifi"
                :items="[ { name: 'Yes', value: 1 }, { name: 'No', value: 0 } ]"
                label="Public Wifi"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class="mb-4"
              />
            </v-col>
            <v-col cols="12" class="col-sm-4">
              <v-select
                v-model.number="dialogStore.data.pub_fac_checkin_early"
                :items="[ { name: 'Yes', value: 1 }, { name: 'No', value: 0 } ]"
                label="Checkin Early"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class="mb-4"
              />
            </v-col>
            <v-col cols="12" class="col-sm-4">
              <v-select
                v-model.number="dialogStore.data.pub_fac_checkout_late"
                :items="[ { name: 'Yes', value: 1 }, { name: 'No', value: 0 } ]"
                label="Checkout Late"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class="mb-4"
              />
            </v-col>
            <v-col cols="12" class="col-sm-4">
              <v-select
                v-model.number="dialogStore.data.pub_fac_room_service"
                :items="[ { name: 'Yes', value: 1 }, { name: 'No', value: 0 } ]"
                label="Room Service"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class="mb-4"
              />
            </v-col>
            <v-col cols="12" class="col-sm-4">
              <v-select
                v-model.number="dialogStore.data.pub_fac_safety_deposit_box"
                :items="[ { name: 'Yes', value: 1 }, { name: 'No', value: 0 } ]"
                label="Safety Deposit Box"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class="mb-4"
              />
            </v-col>
            <v-col cols="12" class="col-sm-4">
              <v-select
                v-model.number="dialogStore.data.pub_fac_ballrom"
                :items="[ { name: 'Yes', value: 1 }, { name: 'No', value: 0 } ]"
                label="Ballroom"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class="mb-4"
              />
            </v-col>
            <v-col cols="12" class="col-sm-4">
              <v-select
                v-model.number="dialogStore.data.pub_fac_banquet"
                :items="[ { name: 'Yes', value: 1 }, { name: 'No', value: 0 } ]"
                label="Banquet"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class="mb-4"
              />
            </v-col>
            <v-col cols="12" class="col-sm-4">
              <v-select
                v-model.number="dialogStore.data.pub_fac_connecting_room"
                :items="[ { name: 'Yes', value: 1 }, { name: 'No', value: 0 } ]"
                label="Connecting Room"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class="mb-4"
              />
            </v-col>
            <v-col cols="12" class="col-sm-4">
              <v-select
                v-model.number="dialogStore.data.pub_fac_fireplace_in_lobby"
                :items="[ { name: 'Yes', value: 1 }, { name: 'No', value: 0 } ]"
                label="Fireplace in Lobby"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class="mb-4"
              />
            </v-col>
            <v-col cols="12" class="col-sm-4">
              <v-select
                v-model.number="dialogStore.data.pub_fac_smoking_none"
                :items="[ { name: 'Yes', value: 1 }, { name: 'No', value: 0 } ]"
                label="Non Smoking Room"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class="mb-4"
              />
            </v-col>
            <v-col cols="12" class="col-sm-4">
              <v-select
                v-model.number="dialogStore.data.pub_fac_smoking_free"
                :items="[ { name: 'Yes', value: 1 }, { name: 'No', value: 0 } ]"
                label="Smoking Area"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class="mb-4"
              />
            </v-col>
            <v-col cols="12" class="col-sm-4">
              <v-select
                v-model.number="dialogStore.data.pub_fac_heater"
                :items="[ { name: 'Yes', value: 1 }, { name: 'No', value: 0 } ]"
                label="Heater"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class="mb-4"
              />
            </v-col>

            <v-col cols="12" class="col-sm-4">
              <v-select
                v-model.number="dialogStore.data.pub_service_bellboy"
                :items="[ { name: 'Yes', value: 1 }, { name: 'No', value: 0 } ]"
                label="Bellboy Service"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class="mb-4"
              />
            </v-col>
            <v-col cols="12" class="col-sm-4">
              <v-select
                v-model.number="dialogStore.data.pub_service_laundry"
                :items="[ { name: 'Yes', value: 1 }, { name: 'No', value: 0 } ]"
                label="Laundry"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class="mb-4"
              />
            </v-col>
            <v-col cols="12" class="col-sm-4">
              <v-select
                v-model.number="dialogStore.data.pub_service_concierge"
                :items="[ { name: 'Yes', value: 1 }, { name: 'No', value: 0 } ]"
                label="Concierge"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class="mb-4"
              />
            </v-col>
            <v-col cols="12" class="col-sm-4">
              <v-select
                v-model.number="dialogStore.data.pub_service_doorman"
                :items="[ { name: 'Yes', value: 1 }, { name: 'No', value: 0 } ]"
                label="Doorman"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class="mb-4"
              />
            </v-col>
            <v-col cols="12" class="col-sm-4">
              <v-select
                v-model.number="dialogStore.data.pub_service_luggage_storage"
                :items="[ { name: 'Yes', value: 1 }, { name: 'No', value: 0 } ]"
                label="Luggage Storage"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class="mb-4"
              />
            </v-col>
            <v-col cols="12" class="col-sm-4">
              <v-select
                v-model.number="dialogStore.data.pub_service_frontdesk"
                :items="[ { name: 'Yes', value: 1 }, { name: 'No', value: 0 } ]"
                label="Frontdesk"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class="mb-4"
              />
            </v-col>
            <v-col cols="12" class="col-sm-4">
              <v-select
                v-model.number="dialogStore.data.pub_service_frontdesk_24"
                :items="[ { name: 'Yes', value: 1 }, { name: 'No', value: 0 } ]"
                label="Frontdesk 24 Hours"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class="mb-4"
              />
            </v-col>
            <v-col cols="12" class="col-sm-4">
              <v-select
                v-model.number="dialogStore.data.pub_service_security_24"
                :items="[ { name: 'Yes', value: 1 }, { name: 'No', value: 0 } ]"
                label="Security 24 Hours"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class="mb-4"
              />
            </v-col>
            <v-col cols="12" class="col-sm-4">
              <v-select
                v-model.number="dialogStore.data.pub_service_staff_multilingual"
                :items="[ { name: 'Yes', value: 1 }, { name: 'No', value: 0 } ]"
                label="Multilingual Staff"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class="mb-4"
              />
            </v-col>
            <v-col cols="12" class="col-sm-4">
              <v-select
                v-model.number="dialogStore.data.pub_service_newspaper"
                :items="[ { name: 'Yes', value: 1 }, { name: 'No', value: 0 } ]"
                label="Newspaper"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class="mb-4"
              />
            </v-col>
            <v-col cols="12" class="col-sm-4">
              <v-select
                v-model.number="dialogStore.data.pub_service_newspaper_stand"
                :items="[ { name: 'Yes', value: 1 }, { name: 'No', value: 0 } ]"
                label="Newspaper Stand"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class="mb-4"
              />
            </v-col>
            <v-col cols="12" class="col-sm-4">
              <v-select
                v-model.number="dialogStore.data.pub_service_wedding"
                :items="[ { name: 'Yes', value: 1 }, { name: 'No', value: 0 } ]"
                label="Wedding"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class="mb-4"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="red darken-1"
            text
            @click="dialogStore.show = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="STORE_PROCESS()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
let searchInterval = null
let searchAreaTimeout = null
export default {
  name: 'StoreManager',
  data: () => ({
    headers: [
      {
        text: 'Client ID',
        value: 'id',
        sortable: false
      },
      { text: 'Name', value: 'name', sortable: false },
      { text: 'Room Type', value: 'rooms', sortable: false },
      { text: 'Area', value: 'kecamatan_name', sortable: false },
      { text: 'Status', value: 'status', align: 'center', sortable: false },
      { text: 'Opsi', value: 'option', sortable: false }
    ],
    tableSearch: '',
    tableLoading: false,
    tableAchievement: 3,
    table: [],
    tableTotal: 0,
    tablePage: 1,
    tableLimit: 25,
    tableSearchStatus: null,
    options: {},
    dialogStore: {
      show: false,
      data: null
    },
    areaquery: '',
    areas: [],
    loadingImageUpload: false
  }),
  computed: {
    ...mapState({
      storeActive: state => state.storeActive
    })
  },
  watch: {
    areaquery (v) {
      if (!v) {
        return
      }
      const findSelectedArea = this.areas.find(r => r.area_name === v)
      if (findSelectedArea) {
        return
      }
      if (searchAreaTimeout) {
        clearTimeout(searchAreaTimeout)
      }
      searchAreaTimeout = setTimeout(() => {
        this.areaGet('?q=' + v)
        clearTimeout(searchAreaTimeout)
      }, 300)
    },
    tableSearch: function (q) {
      if (searchInterval) {
        clearTimeout(searchInterval)
      }
      searchInterval = setTimeout(() => {
        this.STORE_GET(q)
      }, 300)
    },
    tableSearchStatus () {
      this.STORE_GET(this.tableSearch)
    },
    storeActive (v) {
      if (v) {
        this.STORE_GET(this.tableSearch)
      }
    }
  },
  mounted () {
    this.STORE_GET()
  },
  methods: {
    ProcessGallery (img, index) {
      if (img === 'delete') {
        if (!confirm('Delete this image ?')) {
          return false
        }
        this.dialogStore.data.galleries.splice(index, 1)
      } else {
        this.UploadImage('gallery', index || -1)
        // UPLOAD NEW IMAGE HERE
      }
    },
    areaGet (q, id) {
      this.$store.dispatch('AREA_GET', q + (parseInt(id) ? '&id=' + id : '')).then((res) => {
        if (res.status) {
          this.areas = res.data.data || []
        }
      })
    },
    STORE_SHOW (store) {
      if (store) {
        this.dialogStore.data = Object.assign({}, store)
        this.dialogStore.data.galleries = this.$StrToArray(this.dialogStore.data.galleries)
        if (store.kecamatan_name) {
          this.areaquery = store.kecamatan_name
        }
      } else {
        this.dialogStore.data = {
          id: null,
          area: 0,
          lat: '',
          lng: '',
          logo: '',
          banner: '',
          name: '',
          description: '',
          galleries: [],
          phone: '',
          address: '',
          kecamatan_id: 0,
          featured: 1,
          status: 0,
          pay_at_hotel: 0,
          pub_fac_shopping: 0,
          pub_fac_atm: 0,
          pub_fac_beach: 0,
          pub_fac_mountain: 0,
          pub_fac_garden: 0,
          pub_fac_swimming_pool: 0,
          pub_fac_gym: 0,
          pub_fac_restaurant: 0,
          pub_fac_cafe: 0,
          pub_fac_live_music: 0,
          pub_fac_karaoke: 0,
          pub_fac_golf: 0,
          pub_fac_billiard: 0,
          pub_fac_bowling: 0,
          pub_fac_football: 0,

          pub_fac_carpark: 0,
          pub_fac_wifi: 0,
          pub_fac_checkin_early: 0,
          pub_fac_checkout_late: 0,
          pub_fac_room_service: 0,
          pub_fac_safety_deposit_box: 0,
          pub_fac_ballrom: 0,
          pub_fac_banquet: 0,
          pub_fac_connecting_room: 0,
          pub_fac_fireplace_in_lobby: 0,
          pub_fac_smoking_none: 0,
          pub_fac_smoking_free: 0,
          pub_fac_heater: 0,

          pub_service_bellboy: 0,
          pub_service_laundry: 0,
          pub_service_concierge: 0,
          pub_service_doorman: 0,
          pub_service_luggage_storage: 0,
          pub_service_frontdesk: 0,
          pub_service_frontdesk_24: 0,
          pub_service_security_24: 0,
          pub_service_staff_multilingual: 0,
          pub_service_newspaper: 0,
          pub_service_newspaper_stand: 0,
          pub_service_wedding: 0,

          tripadvisor: null
        }
      }
      this.dialogStore.show = true
    },
    STORE_GET (q) {
      const status = this.tableSearchStatus === 0 || this.tableSearchStatus > 0 ? this.tableSearchStatus : ''
      this.tableLoading = true
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      const query = '?c=' + (parseInt(page) > 0 ? '&page=' + page : '') + (parseInt(itemsPerPage) > 0 ? '&limit=' + parseInt(itemsPerPage) : '') + (sortBy.length ? '&sortby=' + sortBy[0] + (sortDesc[0] ? '&sort=DESC' : '&sort=ASC') : '')
      this.$store.dispatch('STORE', query + (q ? ('&q=' + q) : '') + (parseInt(status) === 0 || parseInt(status) > 0 ? '&status=' + status : '')).then((res) => {
        if (res.status) {
          if (this.$store.state.user.role !== 'root' && this.$store.state.user.role !== 'admin') {
            this.table = res.data.data.filter(r => parseInt(r.id) === parseInt(this.$store.state.user.store))
          } else {
            this.table = res.data.data
          }
          this.tableTotal = res.data.data.length || 0
        } else {
          this.table = []
          this.tableTotal = 0
        }
        this.tableLoading = false
      })
    },
    STORE_PROCESS () {
      const processedStore = Object.assign({}, this.dialogStore.data)
      processedStore.galleries = this.$ArrayToStr(processedStore.galleries || '[]')
      if (!processedStore.name) {
        this.$store.dispatch('TOAST', { show: true, message: 'Masukan nama yang valid!' })
        return false
      }
      if (!this.$validatePhone(processedStore.phone)) {
        this.$store.dispatch('TOAST', { show: true, message: 'No Telepon tidak valid!' })
        return false
      }
      if (!processedStore.description) {
        this.$store.dispatch('TOAST', { show: true, message: 'Deskripsi tidak valid!' })
        return false
      }
      processedStore.phone = this.$reformatPhone(processedStore.phone)
      this.$store.dispatch('STORE_PROCESS', processedStore)
        .then((res) => {
          if (res.status) {
            this.STORE_GET(this.tableSearch)
            this.dialogStore.show = false
            this.$store.dispatch('TOAST', { show: true, message: processedStore.id ? 'Berhasil diupdate' : 'Berhasil dibuat' })
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Terjadi kesalahan!' })
          }
        })
    },
    UploadImage (type, index) {
      if (!this.loadingImageUpload) {
        let u = document.getElementById('file-uploader')
        if (u) {
          u.remove()
        }
        setTimeout(() => {
          u = document.createElement('input')
          u.setAttribute('type', 'file')
          u.setAttribute('id', 'file-uploader')
          u.setAttribute('class', 'file-uploader')
          u.setAttribute('accept', '.png,.gif,.jpeg,.jpg')
          u.addEventListener('change', (e) => {
            this.uploadPhotoRender(e, type, index)
          })
          document.body.appendChild(u)
          u.click()
        }, 100)
      }
    },
    async uploadPhotoRender (event, type, index) {
      /* eslint-disable */
      const $ = this
      await $.$filetoBase64(event, async function (res) {
        if (res.status) {
          $.loadingImageUpload = true
          await $.$store.dispatch('UPLOAD_FILE', {
            file: res.data,
            source: type || 'logo'
          }).then((resUpload) => {
            if (resUpload.status) {
              if (resUpload.data.data.res) {
                if (type === 'banner') {
                  $.dialogStore.data.banner = resUpload.data.data.secure_url
                } else if (type === 'gallery') {
                  if (parseInt(index) > -1) {
                    $.dialogStore.data.galleries[index].src = resUpload.data.data.secure_url
                  } else {
                    $.dialogStore.data.galleries.push({ name: '', src: resUpload.data.data.secure_url })
                  }
                } else {
                  $.dialogStore.data.logo = resUpload.data.data.secure_url
                }
              } else {
                $.$store.dispatch('TOAST', { show: true, message: 'Gagal upload, silahkan coba lagi!' })
              }
            }
            $.loadingImageUpload = false
          })
        } else {
          res.data === 'file_size'
            ? $.$store.dispatch('TOAST', { show: true, message: 'Image size no more than 1Mb' }) // ? $.$store.commit('TOAST', { show: true, message: 'Ops, file image is too large. Max size (files < 5Mb & video < 20Mb)' })
            : res.data === 'canceled'
              ? console.log('Upload canceled.')
              : console.log(res.data)
        }
      })
    }
  }
}
</script>
