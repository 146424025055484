<template>
  <v-container class="py-8">
    <v-row no-gutters class="justify-center">
      <!-- <v-col cols="12" class="col-sm-4">
        <v-card class="d-flex align-center justify-center pa-4 fill-width fill-height secondary font-weight-bold" dark outlined>
          12 Products
        </v-card>
      </v-col> -->
      <v-col cols="12">
        <div class="d-flex flex-column align-center justify-center px-4 px-sm-8 pt-8 pt-sm-8 pb-8 white">
          <v-card flat class="d-flex flex-column align-center justify-center pa-4 fill-width font-weight-bold primary--text">
            <v-img
              src="/amithya-logo-dark.png"
              lazy-src="/amithya-logo-dark.png"
              max-width="200px"
              class="d-inline-flex"
            />
            <div class="d-flex align-center pt-6">
              <v-icon class="mr-1" color="primary" small>
                mdi-google-maps
              </v-icon>
              {{ parseInt($store.state.storeActive) ? $store.state.storeData.filter(r => r.id === $store.state.storeActive)[0].name : 'No Hotel Selected' }}
            </div>
          </v-card>
          <v-row v-if="parseInt($store.state.storeActive)" class="d-flex fill-width align-center justify-center pt-8 pb-4">
            <v-col v-if="$role(['manager', 'cashier'], $store.state.user.role)" cols="12" class="col-sm-6">
              <v-card @click.prevent="$router.push('/rooms')" outlined class="pa-4 text-center secondary caption font-weight-bold" dark>
                <v-icon small class="mr-2">
                  mdi-door-open
                </v-icon>
                Rooms
              </v-card>
            </v-col>
            <v-col v-if="$role(['manager', 'warehouse'], $store.state.user.role)" cols="12" class="col-sm-6">
              <v-card @click.prevent="$router.push('/booking')" outlined class="pa-4 text-center secondary caption font-weight-bold" dark>
                <v-icon small class="mr-2">
                  mdi-cash-clock
                </v-icon>
                Order & Booking
              </v-card>
            </v-col>
            <v-col v-if="$role([], $store.state.user.role)" cols="12" class="col-sm-4">
              <v-card @click.prevent="$router.push('/features/main-banner')" outlined class="pa-4 text-center secondary caption font-weight-bold" dark>
                <v-icon small class="mr-2">
                  mdi-feature-search
                </v-icon>
                Features
              </v-card>
            </v-col>
            <v-col v-if="$role(['manager'], $store.state.user.role)" cols="12" class="col-sm-4">
              <v-card @click.prevent="$router.push('/features/voucher')" outlined class="pa-4 text-center secondary caption font-weight-bold" dark>
                <v-icon small class="mr-2">
                  mdi-feature-search
                </v-icon>
                Voucher
              </v-card>
            </v-col>
            <v-col v-if="$role([], $store.state.user.role)" cols="12" class="col-sm-4">
              <v-card @click.prevent="$router.push('/customer')" outlined class="pa-4 text-center secondary caption font-weight-bold" dark>
                <v-icon small class="mr-2">
                  mdi-account-group
                </v-icon>
                Customer
              </v-card>
            </v-col>
            <v-col v-if="$role([], $store.state.user.role)" cols="12" class="col-sm-4">
              <v-card @click.prevent="$router.push('/account-manager')" outlined class="pa-4 text-center secondary caption font-weight-bold" dark>
                <v-icon small class="mr-2">
                  mdi-account-cog
                </v-icon>
                Account Manager
              </v-card>
            </v-col>
            <v-col v-if="$role(['manager'], $store.state.user.role)" cols="12" class="col-sm-4">
              <v-card @click.prevent="$router.push('/store-manager')" outlined class="pa-4 text-center secondary caption font-weight-bold" dark>
                <v-icon small class="mr-2">
                  mdi-store-cog
                </v-icon>
                Client Manager
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Home'
}
</script>
