<template>
  <v-container v-if="storeActive" class="py-2 py-sm-4">
    <v-row no-gutters class="justify-center">
      <v-col cols="12">
        <div class="d-flex align-center justify-center px-0 px-sm-8 pb-0 pb-sm-4 title primary--text">
          <v-icon class="mr-2" color="primary">
            mdi-door-open
          </v-icon>
          Rooms, {{ storeActive ? $store.state.storeData.filter(r => r.id === storeActive)[0].name : 'Undefined Hotel' }}
        </div>
        <v-divider class="my-4" />
        <div>
          <div class="d-flex fill-width justify-center justify-space-between body-2 pb-4 secondary--text">
            <div>
              Result : {{ $store.state.room.rooms.length }} result{{ $store.state.room.rooms.length > 1 ? 's' : '' }} found
            </div>
            <v-btn depressed color="secondary" dark small class="text-capitalize font-weight-bold" @click.prevent="OpenDetail()">
              + New Room Type
            </v-btn>
          </div>
          <v-card
            v-for="(room, iRoom) in $store.state.room.rooms"
            :key="'room-result-' + iRoom"
            flat
            class="d-flex pa-0 white align-center mb-4 rounded-lg"
            style="overflow: hidden;"
          >
            <RoomList :room="room" @open-detail="OpenDetail" @open-gallery="OpenGallery" @open-rooms="OpenRoomsList" />
          </v-card>
        </div>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialogRoom.show"
      persistent
      scrollable
      max-width="720"
    >
      <v-card v-if="dialogRoom.show && dialogRoom.data">
        <v-card-title class="body-1 justify-center font-weight-bold">
          {{ dialogRoom.data.id ? 'Update Room Type' : '+ Create New Room Type' }}
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-4" style="max-height: 75vh;">
          <v-text-field
            v-model="dialogRoom.data.name"
            outlined
            dense
            hide-details
            label="Room Type Name"
            class="mb-4"
          />
          <v-text-field
            v-model.number="dialogRoom.data.price"
            outlined
            dense
            hide-details
            label="Price Default"
            prefix="Rp"
            class="mb-4"
            @keypress="$NumberOnly"
          />
          <v-row no-gutters>
            <v-col cols="12" class="col-sm-6 pr-0 pr-sm-2">
              <v-text-field
                v-model.number="dialogRoom.data.price_weekday"
                outlined
                dense
                hide-details
                label="Price Weekday"
                prefix="Rp"
                class="mb-4"
                @keypress="$NumberOnly"
              />
            </v-col>
            <v-col cols="12" class="col-sm-6 pl-0 pl-sm-2">
              <v-text-field
                v-model.number="dialogRoom.data.price_weekend"
                outlined
                dense
                hide-details
                label="Price Weekend"
                prefix="Rp"
                class="mb-4"
                @keypress="$NumberOnly"
              />
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" class="col-sm-6 pr-0 pr-sm-2">
              <v-text-field
                v-model="dialogRoom.data.bed_type"
                outlined
                dense
                hide-details
                label="Bed Type (e.g 1 Twin Bed, 1 Queen Bed, etc)"
                class="mb-4"
              />
            </v-col>
            <v-col cols="12" class="col-sm-6 pl-0 pl-sm-2">
              <v-text-field
                v-model.number="dialogRoom.data.capacity"
                outlined
                dense
                hide-details
                label="Room Capacity"
                suffix="Guest"
                class="mb-4"
                @keypress="$NumberOnly"
              />
            </v-col>
          </v-row>
          <v-textarea
            v-model="dialogRoom.data.description"
            outlined
            rows="2"
            dense
            hide-details
            label="Description"
            class="mb-4"
          />
          <v-textarea
            v-model="dialogRoom.data.rules"
            outlined
            rows="2"
            dense
            hide-details
            label="Rules"
            class="mb-4"
          />
          <v-row no-gutters>
            <v-col cols="12" class="col-sm-4">
              <v-select
                v-model.number="dialogRoom.data.fac_24"
                :items="[ { name: 'Yes', value: 1 }, { name: 'No', value: 0 } ]"
                label="24 Hours Service"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class="mb-4"
              />
            </v-col>
            <v-col cols="12" class="col-sm-4 px-0 px-sm-4">
              <v-select
                v-model.number="dialogRoom.data.fac_ac"
                :items="[ { name: 'Yes', value: 1 }, { name: 'No', value: 0 } ]"
                label="Air Conditioner"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class="mb-4"
              />
            </v-col>
            <v-col cols="12" class="col-sm-4">
              <v-select
                v-model.number="dialogRoom.data.fac_wifi"
                :items="[ { name: 'Yes', value: 1 }, { name: 'No', value: 0 } ]"
                label="Wifi / Hotspot"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class="mb-4"
              />
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" class="col-sm-4">
              <v-select
                v-model.number="dialogRoom.data.fac_breakfast"
                :items="[ { name: 'Yes', value: 1 }, { name: 'No', value: 0 } ]"
                label="Breakfast"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class="mb-4"
              />
            </v-col>
            <v-col cols="12" class="col-sm-4 px-0 px-sm-4">
              <v-select
                v-model.number="dialogRoom.data.fac_tv"
                :items="[ { name: 'Yes', value: 1 }, { name: 'No', value: 0 } ]"
                label="Television"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class="mb-4"
              />
            </v-col>
            <v-col cols="12" class="col-sm-4">
              <v-select
                v-model.number="dialogRoom.data.fac_balcony"
                :items="[ { name: 'Yes', value: 1 }, { name: 'No', value: 0 } ]"
                label="Balcony"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class="mb-4"
              />
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" class="col-sm-4">
              <v-select
                v-model.number="dialogRoom.data.fac_bathtub"
                :items="[ { name: 'Yes', value: 1 }, { name: 'No', value: 0 } ]"
                label="Bathtub"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class="mb-4"
              />
            </v-col>
            <v-col cols="12" class="col-sm-4 px-0 px-sm-4">
              <v-select
                v-model.number="dialogRoom.data.fac_water_heater"
                :items="[ { name: 'Yes', value: 1 }, { name: 'No', value: 0 } ]"
                label="Water Heater"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class="mb-4"
              />
            </v-col>
            <v-col cols="12" class="col-sm-4">
              <v-select
                v-model.number="dialogRoom.data.fac_bathrobe"
                :items="[ { name: 'Yes', value: 1 }, { name: 'No', value: 0 } ]"
                label="Bathrobe"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class="mb-4"
              />
            </v-col>
            <v-col cols="12" class="col-sm-4">
              <v-select
                v-model.number="dialogRoom.data.fac_tv_cable"
                :items="[ { name: 'Yes', value: 1 }, { name: 'No', value: 0 } ]"
                label="TV Cable"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class="mb-4"
              />
            </v-col>
            <v-col cols="12" class="col-sm-4 px-0 px-sm-4">
              <v-select
                v-model.number="dialogRoom.data.fac_desk"
                :items="[ { name: 'Yes', value: 1 }, { name: 'No', value: 0 } ]"
                label="Desk"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class="mb-4"
              />
            </v-col>
            <v-col cols="12" class="col-sm-4">
              <v-select
                v-model.number="dialogRoom.data.fac_hair_dryer"
                :items="[ { name: 'Yes', value: 1 }, { name: 'No', value: 0 } ]"
                label="Hair Dryer"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class="mb-4"
              />
            </v-col>
            <v-col cols="12" class="col-sm-4">
              <v-select
                v-model.number="dialogRoom.data.fac_inroom_safe"
                :items="[ { name: 'Yes', value: 1 }, { name: 'No', value: 0 } ]"
                label="In Room Safe"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class="mb-4"
              />
            </v-col>
            <v-col cols="12" class="col-sm-4 px-0 px-sm-4">
              <v-select
                v-model.number="dialogRoom.data.fac_microwave"
                :items="[ { name: 'Yes', value: 1 }, { name: 'No', value: 0 } ]"
                label="Microwave"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class="mb-4"
              />
            </v-col>
            <v-col cols="12" class="col-sm-4">
              <v-select
                v-model.number="dialogRoom.data.fac_minibar"
                :items="[ { name: 'Yes', value: 1 }, { name: 'No', value: 0 } ]"
                label="Minibar"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class="mb-4"
              />
            </v-col>
            <v-col cols="12" class="col-sm-4">
              <v-select
                v-model.number="dialogRoom.data.fac_shower"
                :items="[ { name: 'Yes', value: 1 }, { name: 'No', value: 0 } ]"
                label="Shower"
                item-text="name"
                item-value="value"
                outlined
                dense
                hide-details
                class="mb-4"
              />
            </v-col>
          </v-row>
          <v-select
            v-model.number="dialogRoom.data.status"
            :items="[ { name: 'Yes', value: 1 }, { name: 'No', value: 0 } ]"
            label="Published ?"
            item-text="name"
            item-value="value"
            outlined
            dense
            hide-details
            class=""
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            color="red darken-1"
            text
            class="mr-2 text-capitalize font-weight-bold"
            @click="dialogRoom.show = false"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            color="secondary darken-1"
            depressed
            class="px-4 text-capitalize font-weight-bold"
            @click="ROOM_PROCESS()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogGallery.show"
      persistent
      scrollable
      max-width="720"
    >
      <v-card v-if="dialogGallery.show && dialogGallery.id" >
        <v-card-title class="body-1 justify-space-between font-weight-bold">
          {{ dialogGallery.title || 'No Title' }} - Gallery
          <v-btn outlined small color="secondary" @click.prevent="UploadImage">
            <v-icon class="mr-1" small>
              mdi-image-plus-outline
            </v-icon>
            Upload
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-6" style="max-height: 75vh;">
          <v-row v-if="!dialogGallery.data.length">
            <v-col class="px-4 py-10 text-center">
              No Gallery
            </v-col>
          </v-row>
          <v-row v-if="dialogGallery.data.length">
            <v-col
              v-for="(g, iG) in dialogGallery.data"
              :key="'gallery-dialog-' + iG"
              class="gallery-dialog p-relative d-flex flex-column col-sm-3"
              cols="6"
            >
              <v-img
                :src="g.src || '/no-image.png'"
                :lazy-src="g.src || '/no-image.png'"
                aspect-ratio="1"
                class="grey lighten-2 fill-width"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    />
                  </v-row>
                </template>
              </v-img>
              <div class="d-flex fill-width align-center justify-center">
                <v-btn x-small text class="fill-width text-capitalize mt-1" color="red" @click.prevent="dialogGallery.data.splice(iG, 1)">
                  <v-icon small class="mr-1">
                    mdi-trash
                  </v-icon>
                  Remove
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            color="red"
            text
            class="px-4 text-capitalize font-weight-bold"
            @click="dialogGallery.show = false"
          >
            Close
          </v-btn>
          <v-spacer />
          <v-btn
            color="secondary"
            depressed
            class="px-4 text-capitalize font-weight-bold"
            @click="GALLERY_PROCESS(true)"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogRoomList.show"
      scrollable
      persistent
      max-width="375"
    >
      <v-card>
        <v-card-title v-if="dialogRoomList.room" class="body-1 justify-center font-weight-bold">
          {{ dialogRoomList.room.name }}
        </v-card-title>
        <v-divider />
        <v-card-text v-if="dialogRoomList.room" class="px-4 py-2" style="max-height: 75vh;">
          <div v-for="(rl, iRl) in dialogRoomList.data" :key="'room-list-' + iRl" class="d-flex fill-width align-center justify-start justify-sm-space-between py-2">
            <div class="d-flex align-center fill-width subtitle-1 pr-2">
              <v-icon class="mr-2" color="secondary">
                mdi-door-sliding
              </v-icon>
              <v-text-field
                v-model.lazy="dialogRoomList.data[iRl].name"
                outlined
                dense
                hide-details
                color="secondary"
                label="Room Name"
                @keyup="ROOM_LIST_PROCESS(rl.id, rl.room, dialogRoomList.data[iRl].name, dialogRoomList.data[iRl].status)"
              />
            </div>
            <v-select
              v-model.number="dialogRoomList.data[iRl].status"
              :items="[ { name: 'Yes', value: 1 }, { name: 'No', value: 0 } ]"
              label="Sell this room?"
              item-text="name"
              item-value="value"
              outlined
              dense
              color="secondary"
              hide-details
              @change="ROOM_LIST_PROCESS(rl.id, rl.room, dialogRoomList.data[iRl].name, dialogRoomList.data[iRl].status)"
            />
            <v-btn v-if="!parseInt(rl.id) && !dialogRoomList.loading" x-small depressed dark color="red" fab class="ml-2" @click.prevent="dialogRoomList.data.splice(iRl, 1)">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </div>
          <div class="pa-4 d-flex justify-center">
            <v-btn :loading="dialogRoomList.loading" text color="secondary" x-small class="text-capitalize rounded-pill" @click.prevent="AddNewRoom()">
              + Add Room
            </v-btn>
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="red darken-1"
            text
            class="mr-2 text-capitalize font-weight-bold"
            @click="LOAD_ROOMS(), dialogRoomList.show = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
let delayRoom = null
export default {
  name: 'Rooms',
  data: () => ({
    dialogRoom: {
      show: false,
      data: null
    },
    dialogRoomList: {
      show: false,
      loading: false,
      room: null,
      data: []
    },
    dialogGallery: {
      show: false,
      id: 0,
      title: 0,
      data: []
    },
    loadingImageUpload: false
  }),
  computed: {
    ...mapState({
      storeActive: state => state.storeActive
    })
  },
  watch: {
    storeActive (v) {
      if (v) {
        this.LOAD_ROOMS()
      }
    }
  },
  mounted () {
    if (parseInt(this.storeActive)) {
      this.LOAD_ROOMS()
    }
  },
  methods: {
    AddNewRoom () {
      if (!confirm('Create new room ?')) {
        return false
      } else if (!this.dialogRoomList.data.find(r => !parseInt(r.id))) {
        this.dialogRoomList.data.push({ name: 'Room Name', room: this.dialogRoomList.room.id, status: 0 })
      }
    },
    ROOM_LIST_PROCESS (id, room, name, status) {
      if (delayRoom) {
        clearTimeout(delayRoom)
      }
      if (!parseInt(room) || !name) {
        this.$store.dispatch('TOAST', { show: true, message: 'Please input room name!' })
        return
      }
      if (!id) {
        this.dialogRoomList.loading = true
      }
      delayRoom = setTimeout(() => {
        this.$store.dispatch('room/ROOMS_LIST_PROCESS', {
          id,
          hotel: this.storeActive,
          room,
          name,
          status
        }).then((res) => {
          if (res.status) {
            if (parseInt(res.data.data.id) && !id) {
              const index = this.dialogRoomList.data.findIndex(r => !r.id)
              if (index > -1) {
                this.dialogRoomList.loading = false
                this.dialogRoomList.data[index].id = parseInt(res.data.data.id)
              }
            }
          }
        })
      }, 500)
    },
    OpenRoomsList (v) {
      this.dialogRoomList.loading = false
      this.dialogRoomList.data = []
      this.dialogRoomList.room = Object.assign({}, v)
      this.$store.dispatch('room/ROOMS_LIST_GET', {
        room: v.id
      }).then((res) => {
        if (res.status) {
          this.dialogRoomList.data = res.data.data || []
          this.dialogRoomList.show = true
        } else {
          this.$store.dispatch('TOAST', { show: true, message: 'Something went wrong!' })
        }
      })
    },
    OpenGallery (params) {
      const g = this.$StrToArray(params.galleries)
      if (g) {
        this.dialogGallery.id = parseInt(params.id)
        this.dialogGallery.title = params.title || ''
        this.dialogGallery.data = g
        this.dialogGallery.show = true
      } else {
        this.dialogGallery.data = []
        this.dialogGallery.show = false
        this.$store.dispatch('TOAST', { show: true, message: 'Failed to load galleries!' })
      }
    },
    async LOAD_ROOMS () {
      await this.$store.dispatch('room/ROOMS_GET', '?hotel=' + this.storeActive + '&show=all')
        .then((res) => {
        })
    },
    OpenDetail (v) {
      this.dialogRoom.data = null
      this.dialogRoom.data = v ? Object.assign({}, v) : {
        id: null,
        hotel: this.storeActive,
        name: '',
        description: '',
        rules: '',
        bed_type: '',
        capacity: 1,
        price: 0,
        price_discount: 0,
        fac_24: 0,
        fac_ac: 0,
        fac_wifi: 0,
        fac_breakfast: 0,
        fac_balcony: 0,
        fac_tv: 0,
        fac_water_heater: 0,
        fac_bathrobe: 0,
        fac_tv_cable: 0,
        fac_desk: 0,
        fac_hair_dryer: 0,
        fac_inroom_safe: 0,
        fac_microwave: 0,
        fac_minibar: 0,
        fac_shower: 0,
        status: 0,
        galleries: null
      }
      this.dialogRoom.show = true
    },
    ROOM_PROCESS () {
      if (!this.dialogRoom.data.name) {
        this.$store.dispatch('TOAST', { show: true, message: 'Please input name!' })
        return false
      }
      if (!parseInt(this.dialogRoom.data.price)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Please input default price!' })
        return false
      }
      if (!this.dialogRoom.data.bed_type) {
        this.$store.dispatch('TOAST', { show: true, message: 'Please input type of bed!' })
        return false
      }
      if (!parseInt(this.dialogRoom.data.capacity)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Please input capacity!' })
        return false
      }
      if (!parseInt(this.dialogRoom.data.hotel)) {
        this.dialogRoom.data.hotel = this.storeActive
      }
      this.$store.dispatch('room/ROOM_PROCESS', this.dialogRoom.data)
        .then((res) => {
          if (res.status) {
            this.LOAD_ROOMS()
            this.$store.dispatch('TOAST', { show: true, message: parseInt(this.dialogRoom.data.id) ? 'Updated!' : 'Created!' })
            this.dialogRoom.show = false
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Ops, something went wrong!' })
          }
        })
    },
    GALLERY_PROCESS (directUpdate) {
      this.loadingImageUpload = true
      this.$store.dispatch('room/ROOM_PROCESS', { id: this.dialogGallery.id, galleries: this.$ArrayToStr(this.dialogGallery.data) })
        .then((r) => {
          this.loadingImageUpload = false
          if (r.status) {
            this.$store.dispatch('TOAST', { show: true, message: directUpdate ? 'Gallery Updated!' : 'Uploaded!' })
            if (directUpdate) {
              this.dialogGallery.show = false
              this.LOAD_ROOMS()
            }
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Failed!' })
          }
        })
    },
    UploadImage () {
      if (!this.loadingImageUpload) {
        let u = document.getElementById('file-uploader')
        if (u) {
          u.remove()
        }
        setTimeout(() => {
          u = document.createElement('input')
          u.setAttribute('type', 'file')
          u.setAttribute('id', 'file-uploader')
          u.setAttribute('class', 'file-uploader')
          u.setAttribute('accept', '.png,.gif,.jpeg,.jpg')
          u.addEventListener('change', (e) => {
            this.uploadPhotoRender(e)
          })
          document.body.appendChild(u)
          u.click()
        }, 100)
      }
    },
    async uploadPhotoRender (event) {
      /* eslint-disable */
      const $ = this
      $.loadingImageUpload = true
      await $.$filetoBase64(event, async function (res) {
        if (res.status) {
          await $.$store.dispatch('UPLOAD_FILE', {
            file: res.data,
            source: parseInt($.storeActive || 0) + '-gallery'
          }).then((resUpload) => {
            if (resUpload.status) {
              if (resUpload.data.data.res) {
                $.dialogGallery.data.push({ name: '', src: resUpload.data.data.secure_url })
                $.GALLERY_PROCESS()
              } else {
                $.loadingImageUpload = false
                $.$store.dispatch('TOAST', { show: true, message: 'Ops, something went wrong!' })
              }
            } else {
              $.loadingImageUpload = false
              $.$store.dispatch('TOAST', { show: true, message: 'Upload failed, please try again!' })
            }
          })
        } else {
          $.loadingImageUpload = false
          res.data === 'file_size'
            ? $.$store.dispatch('TOAST', { show: true, message: 'Image size no more than 1Mb' }) // ? $.$store.commit('TOAST', { show: true, message: 'Ops, file image is too large. Max size (files < 5Mb & video < 20Mb)' })
            : res.data === 'canceled'
              ? console.log('Upload canceled.')
              : console.log(res.data)
        }
      })
    }
  }
}
</script>
