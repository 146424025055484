<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12" class="col-sm-8 white">
        <div class="px-4 pt-4 pb-0 pb-sm-4">
          <v-text-field
            v-model="tableSearch"
            outlined
            dense
            hide-details
            class="rounded-0"
            placeholder="Search"
            prepend-inner-icon="mdi-magnify"
          />
        </div>
      </v-col>
      <v-col cols="12" class="col-sm-4">
        <v-card class="d-flex align-center justify-center pa-4 fill-width fill-height secondary" dark outlined @click.prevent="VOUCHER_SHOW()">
          <v-icon small class="mr-2">
            mdi-plus
          </v-icon>
          New Voucher
        </v-card>
      </v-col>
      <!-- <v-col cols="12" class="col-sm-2 white">
        <div class="pa-4 pl-sm-0">
          <v-select
            v-model="tableSearchStatus"
            :items="[{ name: 'All Status', value: null }, { name: 'Pending', value: 0 }, { name: 'Packaging', value: 1 }, { name: 'Ready To Ship', value: 2 }, { name: 'Shipped', value: 3 }, { name: 'Complete', value: 4 }]"
            item-text="name"
            item-value="value"
            outlined
            dense
            hide-details
            class="rounded-0"
            label="Order Status"
          />
        </div>
      </v-col> -->
      <v-col cols="12" class="pt-4">
        <v-data-table
          dense
          :headers="headers"
          :items="table"
          item-key="id"
          :server-items-length="tableTotal"
          :page.sync="tablePage"
          :items-per-page.sync="tableLimit"
          :options.sync="options"
          :footer-props="{
            itemsPerPageOptions: [25, 50, 100]
          }"
          :loading="tableLoading"
          loading-text="Loading... Please wait"
        >
          <template v-slot:[`item.judul`]="{ item }">
            <div class="py-2">
              <div>
                {{ item.judul }}
              </div>
            </div>
          </template>
          <template v-slot:[`item.voucher_code`]="{ item }">
            <div class="dont-weight-bold">
              {{ item.voucher_code }}
            </div>
          </template>
          <template v-slot:[`item.tanggal_berlaku`]="{ item }">
            <div>
              {{ $localDT(item.tanggal_berlaku, 'display') }}
            </div>
          </template>
          <template v-slot:[`item.tanggal_expire`]="{ item }">
            <div>
              {{ $localDT(item.tanggal_expire, 'display') }}
            </div>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <div>
              {{ !parseInt(item.status) ? 'Tidak Aktif' : 'Aktif' }}
            </div>
          </template>
          <template v-slot:[`item.detail`]="{ item }">
            <div class="py-2">
              <div>
                <v-chip v-if="parseInt(item.voucher_partner)" x-small dark color="orange">
                  Partner Only
                </v-chip>
                <v-chip v-else x-small dark color="grey">
                  Default (All customer)
                </v-chip>
              </div>
              <div>
                <v-chip x-small dark color="grey">
                  {{ item.validfor === 'daily' ? 'Daily Use' : 'One time use' }}
                </v-chip>
              </div>
            </div>
          </template>
          <template v-slot:[`item.option`]="{ item }">
            <div style="min-width: 100px;">
              <v-btn v-if="$role(['manager'], $store.state.user.role)" depressed small dark color="orange" class="rounded-0 text-capitalize" @click.prevent="VOUCHER_SHOW(item)">
                <v-icon small class="mr-2">
                  mdi-information-outline
                </v-icon>
                Detail
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialogVoucher.show"
      persistent
      scrollable
      max-width="340"
    >
      <v-card v-if="dialogVoucher.data" >
        <v-card-title class="body-1 justify-center">
          {{ dialogVoucher.data.id ? 'Update Voucher' : 'Create New Voucher' }}
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-2" style="max-height: 75vh;">
          <div class="" style="position: relative;" @click.prevent="UploadImage()">
            <v-img
              :src="dialogVoucher.data.banner"
              :aspect-ratio="2/1"
              class="blue lighten-4 fill-width mb-4"
            />
            <div class="d-flex fill-width fill-height align-center justify-center caption c-pointer text-center" style="position: absolute;top: 0px;left:0px;">
              <span v-if="loadingImageUpload">
                uploading ...
              </span>
              <span v-else>
                {{ dialogVoucher.data.image ? '+ Change Image' : '+ Add Image' }}<br>
                2:1
              </span>
            </div>
          </div>
          <v-text-field
            v-model="dialogVoucher.data.judul"
            outlined
            dense
            hide-details
            label="Title"
            class="mb-4 rounded-0"
          />
          <v-text-field
            v-model="dialogVoucher.data.voucher_code"
            outlined
            dense
            hide-details
            label="Voucher Code"
            class="mb-4 rounded-0"
            @keyup="dialogVoucher.data.voucher_code = $AlphaNumeric(dialogVoucher.data.voucher_code)"
          />
          <v-text-field
            v-model.number="dialogVoucher.data.voucher_value"
            outlined
            dense
            hide-details
            prefix="Rp"
            label="Voucher Value (Discount)"
            class="mb-4 rounded-0"
            @keypress="$NumberOnly"
          />
          <v-textarea
            v-model="dialogVoucher.data.keterangan"
            outlined
            dense
            rows="4"
            hide-details
            label="Voucher Terms"
            class="mb-4 rounded-0"
          />
          <!-- <v-select
            v-model="dialogVoucher.data.validfor"
            :items="[
              { name: 'One time use', value: '' },
              { name: 'Daily Use', value: 'daily' }
            ]"
            item-text="name"
            item-value="value"
            outlined
            dense
            hide-details
            class="mb-4 rounded-0"
            label="Usage type"
          /> -->
          <!-- <v-select
            v-model.number="dialogVoucher.data.voucher_partner"
            :items="[
              { name: 'Partner Only', value: 1 },
              { name: 'Default (All Customer)', value: 0 }
            ]"
            item-text="name"
            item-value="value"
            outlined
            dense
            hide-details
            class="mb-4 rounded-0"
            label="Is for partner only ?"
          /> -->
          <!-- <v-select
            v-model.number="dialogVoucher.data.voucher_show"
            :items="[
              { name: 'Yes (show on app voucher List)', value: 1 },
              { name: 'No (hide from app)', value: 0 }
            ]"
            item-text="name"
            item-value="value"
            outlined
            dense
            hide-details
            class="mb-4 rounded-0"
            label="Show on App"
          /> -->
          <v-flex xs12>
            <v-menu
              ref="menuTanggalBerlaku"
              v-model="menuTanggalBerlaku"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="dialogVoucher.data.tanggal_berlaku"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="dialogVoucher.data.tanggal_berlaku"
                  label="Valid From"
                  prepend-inner-icon="mdi-application"
                  readonly
                  outlined
                  dense
                  hide-details
                  class="mb-4"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="dialogVoucher.data.tanggal_berlaku" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn small color="primary" @click="menuTanggalBerlaku = false">Cancel</v-btn>
                <v-btn small color="primary" @click="$refs.menuTanggalBerlaku.save(dialogVoucher.data.tanggal_berlaku)">OK</v-btn>
              </v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex>
            <v-menu
              ref="menuTanggalExpire"
              v-model="menuTanggalExpire"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="dialogVoucher.data.tanggal_expire"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="dialogVoucher.data.tanggal_expire"
                  label="Expire Date"
                  prepend-inner-icon="mdi-av-timer"
                  readonly
                  outlined
                  dense
                  hide-details
                  class="mb-4"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="dialogVoucher.data.tanggal_expire" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn small color="primary" @click="menuTanggalExpire = false">Cancel</v-btn>
                <v-btn small color="primary" @click="$refs.menuTanggalExpire.save(dialogVoucher.data.tanggal_expire)">OK</v-btn>
              </v-date-picker>
            </v-menu>
          </v-flex>
          <v-select
            v-model.number="dialogVoucher.data.status"
            :items="[
              { name: 'Aktif', value: 1 },
              { name: 'Tidak Aktif', value: 0 }
            ]"
            item-text="name"
            item-value="value"
            outlined
            dense
            hide-details
            class="mb-4 rounded-0"
            label="Publish Status"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="red darken-1"
            text
            class="text-capitalize"
            @click="dialogVoucher.show = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            class="text-capitalize"
            @click="VOUCHER_PROCESS()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
let searchInterval = null
export default {
  name: 'Feature',
  data: () => ({
    menuTanggalBerlaku: false,
    menuTanggalExpire: false,
    headers: [
      { text: 'Title', value: 'judul' },
      { text: 'Voucher', value: 'voucher_code' },
      { text: 'Valid From', value: 'tanggal_berlaku' },
      { text: 'Expire', value: 'tanggal_expire' },
      // { text: 'Detail', value: 'detail', sortable: false },
      { text: 'Opsi', value: 'option', sortable: false }
    ],
    tableSearch: '',
    tableLoading: false,
    tableAchievement: 3,
    table: [],
    tableTotal: 0,
    tablePage: 1,
    tableLimit: 25,
    tableSearchStatus: null,
    options: {},
    dialogVoucher: {
      show: false,
      data: null
    },
    loadingImageUpload: false
  }),
  computed: {
    ...mapState({
      storeActive: state => state.storeActive
    })
  },
  watch: {
    tableSearch: function (q) {
      if (searchInterval) {
        clearTimeout(searchInterval)
      }
      searchInterval = setTimeout(() => {
        this.VOUCHER_GET(q)
      }, 300)
    },
    tableSearchStatus () {
      this.VOUCHER_GET(this.tableSearch)
    },
    storeActive (v) {
      if (v) {
        this.VOUCHER_GET(this.tableSearch)
      }
    }
  },
  mounted () {
    this.VOUCHER_GET()
  },
  methods: {
    VOUCHER_SHOW (voucher) {
      this.menuTanggalBerlaku = false
      this.menuTanggalExpire = false
      if (voucher) {
        voucher.validfor = voucher.validfor || ''
        voucher.tanggal_berlaku = new Date(voucher.tanggal_berlaku).toISOString().substr(0, 10)
        voucher.tanggal_expire = new Date(voucher.tanggal_expire).toISOString().substr(0, 10)
        this.dialogVoucher.data = Object.assign({}, voucher)
      } else {
        this.dialogVoucher.data = {
          id: null,
          store: parseInt(this.$store.state.storeActive),
          judul: '',
          banner: '',
          keterangan: '',
          snk: '',
          voucher_type: '',
          voucher_code: '',
          voucher_value: '',
          voucher_value_type: '',
          voucher_value_percent_maximum: '',
          validfor: '',
          voucher_value_minimum: 0,
          tanggal_dibuat: new Date(),
          tanggal_berlaku: new Date().toISOString().substr(0, 10),
          tanggal_expire: new Date().toISOString().substr(0, 10),
          voucher_users: '[]',
          voucher_partner: 0,
          voucher_show: 1,
          voucher_limit: 0,
          status: 1
        }
      }
      this.dialogVoucher.show = true
    },
    VOUCHER_GET (q) {
      const status = this.tableSearchStatus === 0 || this.tableSearchStatus > 0 ? this.tableSearchStatus : ''
      this.tableLoading = true
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      const query = '?c=' + ('&store=' + this.$store.state.storeActive) + (parseInt(page) > 0 ? '&page=' + page : '') + (parseInt(itemsPerPage) > 0 ? '&limit=' + parseInt(itemsPerPage) : '') + (sortBy.length ? '&sortby=' + sortBy[0] + (sortDesc[0] ? '&sort=DESC' : '&sort=ASC') : '')
      this.$store.dispatch('feature/VOUCHER_GET', query + (q ? ('&q=' + q) : '') + (parseInt(status) === 0 || parseInt(status) > 0 ? '&status=' + status : '') + '&store=' + (this.$store.state.storeActive || 0)).then((res) => {
        if (res.status) {
          this.table = res.data.data
          this.tableTotal = res.data.data.length
        } else {
          this.table = []
          this.tableTotal = 0
        }
        this.tableLoading = false
      })
    },
    VOUCHER_PROCESS () {
      const processedVoucher = Object.assign({}, this.dialogVoucher.data)
      if (!this.$validateName(processedVoucher.judul)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Masukan judul yang valid!' })
        return false
      }
      if (!processedVoucher.keterangan) {
        this.$store.dispatch('TOAST', { show: true, message: 'Masukan syarat & ketentuan!' })
        return false
      }
      this.$store.dispatch('feature/VOUCHER_PROCESS', processedVoucher)
        .then((res) => {
          if (res.status) {
            this.VOUCHER_GET(this.tableSearch)
            this.dialogVoucher.show = false
            this.$store.dispatch('TOAST', { show: true, message: processedVoucher.id ? 'Berhasil diupdate' : 'Berhasil dibuat' })
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Terjadi kesalahan!' })
          }
        })
    },
    UploadImage () {
      if (!this.loadingImageUpload) {
        let u = document.getElementById('file-uploader')
        if (u) {
          u.remove()
        }
        setTimeout(() => {
          u = document.createElement('input')
          u.setAttribute('type', 'file')
          u.setAttribute('id', 'file-uploader')
          u.setAttribute('class', 'file-uploader')
          u.setAttribute('accept', '.png,.gif,.jpeg,.jpg')
          u.addEventListener('change', (e) => {
            this.uploadPhotoRender(e)
          })
          document.body.appendChild(u)
          u.click()
        }, 100)
      }
    },
    async uploadPhotoRender (event) {
      /* eslint-disable */
      const $ = this
      await $.$filetoBase64(event, async function (res) {
        if (res.status) {
          $.loadingImageUpload = true
          await $.$store.dispatch('UPLOAD_FILE', {
            file: res.data,
            source: 'voucher'
          }).then((resUpload) => {
            if (resUpload.status) {
              if (resUpload.data.data.res) {
                $.dialogVoucher.data.banner = resUpload.data.data.secure_url
              } else {
                $.$store.dispatch('TOAST', { show: true, message: 'Gagal upload, silahkan coba lagi!' })
              }
            }
            $.loadingImageUpload = false
          })
        } else {
          res.data === 'file_size'
            ? $.$store.dispatch('TOAST', { show: true, message: 'Image size no more than 1Mb' }) // ? $.$store.commit('TOAST', { show: true, message: 'Ops, file image is too large. Max size (files < 5Mb & video < 20Mb)' })
            : res.data === 'canceled'
              ? console.log('Upload canceled.')
              : console.log(res.data)
        }
      })
    }
  }
}
</script>
